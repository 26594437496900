@use 'sass:color';
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import './theme';
@import '@dps-es/common/styles/materialize';
@import '@dps-es/common/styles/global';

.sidenav {
  overflow-y: hidden;

  & .background {
    text-align: center;
    background: var(--primary-color);

    & > img {
      width: 350px;
      position: relative;
      left: 50%;
      transform: translate(-43%, -38px);
    }
  }

  & .user-view {
    height: 150px;
    padding: 16px 16px 0;
    background-image: linear-gradient(
      0deg,
      rgb(0 0 0) 0,
      rgba(28 30 31 / 30%) 45%,
      rgba(0 0 0 / 0%)
    );

    img {
      object-fit: cover;
    }

    & > .sidenav-close {
      display: none;
      position: absolute;
      color: black;
      top: 5%;
      right: 5%;
      cursor: pointer;

      &.show {
        display: block;
      }
    }
  }

  & .scrollable {
    overflow-y: auto;
    height: calc(100vh - 150px - 125px + 14px);
    height: calc((var(--vh, 1vh) * 100) - 150px - 125px + 14px - 16px);

    &.always-expanded {
      height: calc((var(--vh, 1vh) * 100) - 150px - 125px + 14px - 16px - 50px);
    }
  }

  a.collapsible-header {
    padding: 0 32px;

    .dropdown {
      position: absolute;
      width: 80px;
      right: 32px;
      transform: translateX(32px);
      margin-right: 0;
      transition: transform 0.25s linear;
    }
  }

  li.active .dropdown {
    transform: translateX(32px) rotate(180deg);
  }

  .ovf-hidden {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ourbrand {
    position: absolute;
    text-align: center;

    & > .divider {
      margin-bottom: 0.75rem;
    }

    #dps-web-link {
      display: inline;

      &:hover {
        background: unset;
      }
    }

    img {
      width: 65%;
      transition: all 1s ease;

      &:hover {
        transform: scale(1.25);
      }
    }

    & > .version {
      margin: 0;
      line-height: 0px;
      transform: translate(-10px, -22px);
    }
  }

  &-trigger-style {
    float: left;
    position: relative;
    z-index: 1;
    height: 56px;
    margin: 0 18px;
  }
}

.ellipsis {
  max-width: 85%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
}

select.browser-default {
  appearance: none;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0;
  outline: none;
  height: calc(2.5rem - 10px);
  width: 100%;
  font-size: 16px;
  margin: 0 0 8px;
  padding: 5px 1px;
  box-shadow: none;
  box-sizing: content-box;
  transition: box-shadow 0.3s, border 0.3s;
  line-height: 1.3;

  &:focus {
    border-bottom: 1px solid var(--secondary-color);
    box-shadow: 0 1px 0 0 var(--secondary-color);
  }
}

.item-mainmenu {
  .star-mainmenu {
    display: none;
    color: gold;
  }

  .fav-mainmenu {
    color: gold;
  }

  &:hover {
    .star-mainmenu {
      display: inline-block;
    }
  }
}

.my-collection,
.my-card {
  max-width: 750px;
  margin: auto;
  margin-bottom: calc(56px + 23px + 1vh);

  .collection-item {
    cursor: pointer;

    & > a {
      color: black;
    }
  }

  &.faded {
    transition: opacity 0.2s linear;
    opacity: 0;
  }
}

.nav-wrapper > form {
  position: absolute;
  transform: translateX(calc(50vw - 50%));
  width: 50vw;

  .navbar-search-line {
    background: rgb(255 255 255 / 45%);
    height: 0.05rem;
    margin-left: 4rem;
    position: relative;
    top: 75%;
    width: calc(92.5% - 4rem);
  }

  input {
    color: white;

    &:focus {
      color: black;
    }
  }
}

#search:disabled {
  color: #738fab;
}

.master-comment {
  margin: 0 !important;
  margin-right: 2.5vw !important;
  margin-left: 2.5vw !important;
  flex-basis: 90% !important;
}

.bottom-navigation {
  position: fixed;
  bottom: 0;
  height: 50px;

  & > .nav-wrapper > div {
    padding-left: 2.5vw;
    line-height: 50px;

    .special-separator::before {
      content: '/';
    }
  }
}

.context-menu {
  box-shadow: 0 4px 5px 3px rgb(0 0 0 / 20%);
  position: fixed;
  display: none;
  opacity: 0;
  width: 0;
  height: 0;
  background: white;
  color: white;
  transition: all 0.25s ease, color 0.1s ease-out;
  z-index: 10000;

  &.show {
    opacity: 1;
    height: calc(41px * 6);
    width: 162px;
    transition: all 0.25s ease, color 0.15s ease-out 0.1s;
    color: black;

    .menu-option.disabled,
    .menu-option.disabled:hover {
      color: grey;
      background: lightgrey;
      cursor: default;
    }
  }

  .menu-options {
    list-style: none;
    margin: 0;

    .menu-option {
      font-weight: 500;
      font-size: 14px;
      padding: 10px 40px 10px 20px;
      cursor: pointer;
      white-space: nowrap;

      a {
        color: black;
      }

      &:hover {
        background: rgb(0 0 0 / 20%);
      }
    }
  }
}

.input-field input[type='search']:focus:not(.browser-default) ~ .filter-icon {
  color: #444;
}

.filter-icon {
  position: absolute;
  top: 0;
  right: 1rem;
  color: transparent;
  font-size: 2rem;
  transition: 0.3s color;
  transform: translateX(-40px);
}

#filter-clear {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

#search.whith-filter {
  padding-right: 6rem;
  width: calc(100% - 10rem);
}

#filter-form {
  .trifasico {
    margin-top: 12.5px;

    input[type='checkbox']:not(:indeterminate) {
      &:not(:checked) + span {
        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          z-index: 0;
          border: 1px solid var(--primary-color);
          border-radius: 1px;
          margin-top: 3px;
          transition: 0.2s;
          left: 9px;
          height: 20px;
          width: 0;
        }

        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
      }

      &:checked + span::before {
        border-right-color: #4caf50;
        border-bottom-color: #4caf50;
      }
    }

    label {
      color: black;
      font-size: 1rem;
      padding-left: 5px;

      .crosshair {
        float: right;
        margin-right: -10px;
      }
    }
  }
}

@media #{$small-and-down} {
  .sidenav li > a {
    font-size: 1.15rem;
    white-space: nowrap;
  }
}

@media #{$medium-and-down} {
  .nav-wrapper > form {
    right: calc(-100% + 53px);
    transform: none;
    width: 100vw;
    z-index: 100;

    &:focus-within {
      right: 0;
    }
  }

  .bottom-navigation {
    display: none;
  }
}

.modal.my-bottom-sheet {
  max-height: calc(100vh - 56px - 25px);
}

input[type='month'] {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0;
  outline: none;
  height: 3rem;
  width: 100%;
  font-size: 16px;
  margin: 0 0 8px;
  padding: 0;
  box-shadow: none;
  box-sizing: content-box;
  transition: box-shadow 0.3s, border 0.3s;

  &:focus {
    border-bottom: 1px solid var(--secondary-color);
    box-shadow: 0 1px 0 0 var(--secondary-color);
  }

  &.validate {
    &.valid {
      border-bottom: 1px solid $success-color;
      box-shadow: 0 1px 0 0 $success-color;
    }

    &.invalid {
      border-bottom: 1px solid $error-color;
      box-shadow: 0 1px 0 0 $error-color;
    }
  }
}

@media #{$medium-and-up} {
  #sure-logout,
  .modal.my-bottom-sheet {
    max-width: 550px;
    top: 40vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    bottom: unset !important;
  }

  .master-comment {
    margin-right: 40px !important;
    margin-left: 0.5vw !important;

    // flex-basis: 85% !important;
  }

  .modal.my-bottom-sheet {
    max-height: unset;
  }
}

@media #{$large-and-up} {
  .my-collection,
  .my-card {
    margin-top: 5vh;
  }
}

#modal-incidencia-crear {
  max-width: 700px;

  .modal-proyecto-incidencia {
    margin-top: 29px;
  }

  .incidencia-span {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: red;

    span {
      margin-right: 5px;
    }
  }

  dps-select label {
    left: 0;
  }

  .container-botones {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }

  .container-previsualizacion-incidencia {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;

    #img-capturar-incidencia,
    #video-grabar-incidencia {
      width: 100%;
    }
  }

  #video-capturar-incidencia {
    height: 1px;
    width: 1px;
    opacity: 0;
  }
}

.count-notifications {
  background-color: var(--secondary-color);
}
